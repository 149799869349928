<template>
  <div class="content-sticky" ref="content" v-anim-opacity-fader>
    <!-- Header Sticky -->
    <header v-if="props.preTitle || props.title" class="content-sticky__header">
      <p v-if="props.preTitle" class="text-l-1" v-anim-reveal-text>
        {{ props.preTitle }}
      </p>
      <div
        v-if="props.title"
        class="title-xl-1 m-top-20px"
        v-anim-reveal-text
        v-html="props.title"
      />
    </header>
    <!-- Content -->
    <div class="content-sticky__header__content m-top-l-1">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WidgetContentStickyExsud',
}
</script>

<script setup>
const props = defineProps({
  preTitle: String,
  title: String,
})
</script>

<style lang="scss">
@import './style.scss';
</style>
